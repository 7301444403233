"use client";
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { keyframes } from "@mui/system";
import Image from "next/image";
import { useTheme } from "@mui/material/styles";

const videos = [
	"/videos/chikitsa-patient.mp4",
	"/videos/chikitsa-receptionist.mp4",
	"/videos/chikitsa-doctor.mp4",
	"/videos/chikitsa-nurse.mp4",
	"/videos/chikitsa-lab-operator.mp4",
	"/videos/chikitsa-pharmacist.mp4",
	"/videos/chikitsa-admin.mp4",
];

const rotate = keyframes`
  0% {
    transform: rotate(0deg) translateX(150px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(150px) rotate(-360deg);
  }
`;

const VideoCircles = () => {
	const theme = useTheme();
	const laptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
	const [contextPosition, setContextPosition] = useState(false);
	const [stickyPosition, setStickyPosition] = useState(false);
	const [endStickyPosition, setEndStickyPosition] = useState(false);
	const videoRefs1 = useRef([]);
	const videoRefs2 = useRef([]);
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
	const [currentSide, setCurrentSide] = useState("left");
	const [isPaused, setIsPaused] = useState(false);
	const [scale, setScale] = useState(0);

	useEffect(() => {
		let timeoutId;

		const playVideo = () => {
			const currentRef =
				currentSide === "left"
					? videoRefs1.current[currentVideoIndex]
					: videoRefs2.current[currentVideoIndex];
			if (currentRef) {
				currentRef.play();
				timeoutId = setTimeout(() => {
					currentRef.pause();
					setNextVideo();
				}, 5000);
			}
		};

		if (!isPaused) {
			playVideo();
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [currentVideoIndex, currentSide, isPaused]);

	const setNextVideo = () => {
		if (currentVideoIndex < videos.length - 1) {
			setCurrentVideoIndex(currentVideoIndex + 1);
		} else {
			setCurrentVideoIndex(0);
			setCurrentSide(currentSide === "left" ? "right" : "left");
		}
	};

	const handleVideoClick = (index, side) => {
		setIsPaused(true);
		setCurrentSide(side);
		setCurrentVideoIndex(index % videos.length);
		setIsPaused(false);
	};

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;
			setScale(Math.min(1 + scrollY / 300, 1)); // Adjust scale logic if needed
			setContextPosition(scrollY > 100);
			setStickyPosition(scrollY > 100 && scrollY <= 450);
			setEndStickyPosition(scrollY > 450);
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const [cursorX, setCursorX] = useState(0);
	const [cursorY, setCursorY] = useState(0);
	const [role, setRole] = useState("");
	const [showCursor, setShowCursor] = useState(false);

	useEffect(() => {
		const handleMouseMove = (e) => {
			setCursorX(e.clientX);
			setCursorY(e.clientY);
		};

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	useEffect(() => {
		const roles = [
			"Patient",
			"Receptionist",
			"Doctor",
			"Nurse",
			"Lab Operator",
			"Pharmacist",
			"Admin",
		];
		setRole(roles[currentVideoIndex % roles.length]);
	}, [currentVideoIndex]);

	return (
		<Grid
			container
			className="pt-32 lg:pt-16 lg:h-[100vh] sm:pt-40 pb-10 flex justify-between items-center w-full flex-row sm:px-10 lgg:px-20 gap-10 lg:gap-0"
		>
			<Grid
				item
				className="w-full flex justify-center items-center lg:items-start lg:justify-normal  lg:w-2/4"
			>
				<Box
					justifyContent="center"
					alignItems="center"
					sx={{
						padding: 1,
					}}
					className={`relative px-4 sm:px-20 md:px-40 lg:px-20`}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							flex: "1",
							position: "relative",
						}}
					>
						<Typography
							variant="h1"
							gutterBottom
							className="leading-[40px] sm:leading-[40px] lg:leading-[50px]"
							sx={{
								fontWeight: "800",
								color: "#053C61",
								fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif`,
								fontSize: "clamp(35px, 4vw, 50px)",
							}}
						>
							A Secure and Intelligent Cloud Based HMIS Solution
						</Typography>

						<Typography
							className="text-justify"
							variant="body1"
							gutterBottom
							sx={{
								fontSize: "16px",
								fontWeight: "500",
								lineHeight: "22px",
								color: "black",
							}}
						>
							Our AI-powered, secure, and intelligent cloud-based Hospital
							Management Information System (HMIS) revolutionizes healthcare
							management. Designed to streamline hospital operations, this
							solution ensures top-notch data security and efficiency.
						</Typography>
						<a
							href="https://outlook.office365.com/owa/calendar/ScheduleaDemo@vigorushealth.com/bookings/"
							aria-label="demo to schedule a meeting"
							target="_blank"
							className="mt-4 block w-[215px] sm:w-[250px]"
						>
							<button className="flex flex-row justify-evenly items-center w-[215px] sm:w-[250px] bg-gradient-to-r from-[#053C61] to-[#0A7BC7] text-[white] p-2 border rounded-full font-[600]">
								<Image
									src="/svg/chikitsa-schedule(white).svg"
									alt="chikitsa-checkbox"
									width={30}
									height={30}
								/>
								Schedule a Demo
							</button>
						</a>
					</Box>
				</Box>
			</Grid>

			<Grid item className="w-full lg:w-2/4">
				<Box
					sx={{
						borderRadius: "20px",
						overflow: "visible",
						position: "relative",
					}}
					className="px-4 sm:px-20 md:px-40 lg:px-10"
					onMouseEnter={() => setShowCursor(true)}
					onMouseLeave={() => setShowCursor(false)}
				>
					{currentVideoIndex < videos?.length && (
						<video
							ref={(el) => {
								if (currentSide === "left") {
									videoRefs1.current[currentVideoIndex] = el;
								} else {
									videoRefs2.current[currentVideoIndex] = el;
								}
							}}
							key={`current-video-${currentVideoIndex}`}
							onClick={() =>
								handleVideoClick(currentVideoIndex + 1, currentSide)
							}
							src={videos[currentVideoIndex % videos.length]}
							autoPlay
							muted
							loop
							preload="auto"
							className="rounded-[10px]"
							style={{
								width: "100%",
								height: "auto",
								objectFit: "cover",
							}}
						/>
					)}

					{/* Preload the next video */}
					{currentVideoIndex + 1 < videos?.length && (
						<video
							src={videos[(currentVideoIndex + 1) % videos.length]}
							style={{ display: "none" }} // Hide the video
							preload="auto"
						/>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default VideoCircles;
