"use client";
import { toast } from "react-toastify";
import React, { useContext, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {
	Box,
	Button,
	Grid,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { contactusFormSchema } from "../../lib/shema";
import { contactForm } from "@/app/api/routes";
import Captcha from "../captcha";
import ErrorMessage from "../ErrorMessage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { validateScriptInput } from "@/utils/validateScriptInput";
import { useRouter } from "next/navigation";

const handleNumberKeyPress = (e) => {
	if (e.key == "<" || e.key == ">") {
		e.preventDefault();
	}
	if (isNaN(Number(e.key)) || e.key === " ") {
		return;
	} else {
		e.preventDefault();
	}
};

const Contact = () => {
	const theme = useTheme();
	const router = useRouter();
	const matches = useMediaQuery(theme.breakpoints.up("md"));
	const [userInput, setUserInput] = useState("");
	const [firstNumber, setFirstNumber] = useState(
		Math.floor(Math.random() * 10)
	);
	const [secondNumber, setSecondNumber] = useState(
		Math.floor(Math.random() * 10)
	);
	const [captchaResult, setCaptchaResult] = useState(
		firstNumber + secondNumber
	);

	return (
		<section className="mt-16 w-[100%]" id="contact">
			<Typography
				fontSize={{ md: 30, xs: 24 }}
				fontWeight={1000}
				color={"#053C61"}
				marginBottom={4}
				textAlign={"center"}
			>
				Having a Query?
			</Typography>
			<Box
				width={"100%"}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: { xs: "90%", sm: "80%" },
						height: "80%",
						marginBottom: "14px",
					}}
				>
					<Grid container margin="auto" width="100%">
						<Grid
							item
							xs={12}
							sm={5}
							md={5}
							visibility={!matches ? "hidden" : null}
							display={!matches ? "none" : null}
							width="100%"
						>
							<Box
								className="bg-[url('/img/contactModalLeft.png')]"
								sx={{
									height: "100%",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
								}}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<Box padding={5}>
									<Typography
										fontSize={25}
										fontWeight={900}
										color={"#053C61"}
										marginBottom={4}
									>
										Let’s get in touch
									</Typography>
									<Typography fontWeight={900} color={"#053C61"}>
										If you are having any query, then you may send your query by
										filling the form
									</Typography>
									<Box marginTop={3}>
										<Stack
											direction="row"
											gap={3}
											alignItems="center"
											sx={{ marginBottom: 2 }}
										>
											<PhoneIcon sx={{ color: "#053C61" }} />
											<a href="tel:+91 9509917266">
												<Typography fontWeight={900} color={"#053C61"}>
													+91 9509917266
												</Typography>
											</a>
										</Stack>
										<Stack
											direction="row"
											gap={3}
											alignItems="center"
											sx={{ marginBottom: 2 }}
										>
											<EmailIcon sx={{ color: "#053C61" }} />
											<a href="mailto:connect@chikitsa.io">
												<Typography fontWeight={900} color={"#053C61"}>
													connect@chikitsa.io
												</Typography>
											</a>
										</Stack>
										<Stack
											direction="row"
											gap={3}
											alignItems="center"
											sx={{ marginBottom: 2 }}
										>
											<LocationOnIcon sx={{ color: "#053C61" }} />
											<a
												href="https://maps.app.goo.gl/KzMqpw8m2Re1eznw9"
												target="_blank"
												// rel="noopener noreferrer"
											>
												<Typography fontWeight={900} color={"#053C61"}>
													4th Floor, Horizon Tower at JEWEL OF INDIA, Jawahar
													Lal Nehru Marg, opp. Jaipur Stock Exchange, Jaipur,
													Rajasthan 302017
												</Typography>
											</a>
										</Stack>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={7}>
							<Box
								className="bg-[url('/img/contactModalRight.png')]"
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									height: "100%",
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									bgcolor: !matches ? "#EDF8FB" : null,
								}}
								padding={!matches ? "32px" : "48px"}
							>
								<Formik
									initialValues={{
										name: "",
										phone: "",
										email: "",
										message: "",
										captcha: "",
									}}
									validationSchema={contactusFormSchema}
									onSubmit={(values, actions) => {
										try {
											if (values?.captcha == captchaResult) {
												contactForm(values)
													.then((res) => {
														router.push("/");
														toast.success(res?.message);
														actions.resetForm();
													})
													.catch((error) => {
														toast.error(
															"Something Went Wrong. Please Try Again."
														);
														actions.setSubmitting(false);
													});
											} else {
												toast.error("Invalid Captcha");
												actions.setFieldValue("captcha", "");
												actions.setSubmitting(false);
											}
										} catch (error) {
											toast.error("Something Went Wrong. Please Try Again.");
											actions.setSubmitting(false);
										}
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										setValues,
										setFieldValue,
										isSubmitting,
									}) => (
										<form onSubmit={handleSubmit}>
											<Stack direction="column" gap={2}>
												<TextField
													fullWidth
													id="name"
													name="name"
													value={values.name}
													onBlur={handleBlur}
													onChange={(e) => {
														if (validateScriptInput(e.target.value)) {
															return;
														}
														handleChange(e);
													}}
													onKeyDown={handleNumberKeyPress}
													error={touched.name && Boolean(errors.name)}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<PersonIcon sx={{ color: "#053C61" }} />
															</InputAdornment>
														),
													}}
													sx={{
														backgroundColor: "white",
														"& .MuiInputBase-input": {
															color: "#053C61",
															borderLeft: "1px solid #053C61",
															paddingLeft: "8px",
														},
													}}
													placeholder="Full Name"
												/>
												{touched.name && errors.name ? (
													<ErrorMessage text={errors.name} />
												) : null}
												<TextField
													fullWidth
													id="phone"
													name="phone"
													value={values.phone}
													onKeyDown={(e) => {
														if (isNaN(Number(e.key)) && e.key !== "Backspace") {
															e.preventDefault();
														}
													}}
													onChange={(e) => {
														const newValue = e.target.value;
														if (newValue.length > 10) {
															return;
														}
														if (validateScriptInput(newValue)) {
															return;
														}
														handleChange(e);
													}}
													onBlur={handleBlur}
													error={touched.phone && Boolean(errors.phone)}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<PhoneIcon sx={{ color: "#053C61" }} />
															</InputAdornment>
														),
													}}
													sx={{
														backgroundColor: "white",
														"& .MuiInputBase-input": {
															color: "#053C61",
															borderLeft: "1px solid #053C61",
															paddingLeft: "8px",
														},
													}}
													placeholder="Mobile Number"
												/>
												{touched.phone && errors.phone ? (
													<ErrorMessage text={errors.phone} />
												) : null}
												<TextField
													fullWidth
													id="email"
													name="email"
													value={values.email}
													onChange={(e) => {
														if (validateScriptInput(e.target.value)) {
															return;
														}
														handleChange(e);
													}}
													onBlur={handleBlur}
													error={touched.email && Boolean(errors.email)}
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<EmailIcon sx={{ color: "#053C61" }} />
															</InputAdornment>
														),
													}}
													sx={{
														backgroundColor: "white",
														"& .MuiInputBase-input": {
															color: "#053C61",
															borderLeft: "1px solid #053C61",
															paddingLeft: "8px",
														},
													}}
													placeholder="Email ID"
												/>
												{touched.email && errors.email ? (
													<ErrorMessage text={errors.email} />
												) : null}
												<TextField
													fullWidth
													multiline
													rows={5}
													id="message"
													name="message"
													value={values.message}
													onChange={(e) => {
														if (validateScriptInput(e.target.value)) {
															return;
														}
														handleChange(e);
													}}
													onBlur={handleBlur}
													error={touched.message && Boolean(errors.message)}
													InputProps={{
														style: {
															color: "#053C61",
															backgroundColor: "white",
														},
													}}
													placeholder="Type your query here"
												/>
												{touched.message && errors.message ? (
													<ErrorMessage text={errors.message} />
												) : null}
												<Typography
													sx={{
														color: "#053C61",
														fontWeight: "700",
													}}
												>
													Enter Captcha
												</Typography>
												<Captcha
													values={values.captcha}
													touched={touched}
													errors={errors}
													handleBlur={handleBlur}
													handleChange={handleChange}
													setValues={setValues}
													setUserInput={setUserInput}
													setCaptchaResult={setCaptchaResult}
													firstNumber={firstNumber}
													setFirstNumber={setFirstNumber}
													secondNumber={secondNumber}
													setSecondNumber={setSecondNumber}
													setFieldValue={setFieldValue}
												/>
												<Button
													disabled={isSubmitting}
													type="submit"
													variant="contained"
													sx={{
														textTransform: "none",
														px: 3,
														mt: 2,
														m: "auto",
														width: "40%",
														"&.Mui-disabled": {
															backgroundColor: "#E0E0E0",
															color: "#9E9E9E",
														},
													}}
												>
													Submit
												</Button>
											</Stack>
										</form>
									)}
								</Formik>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</section>
	);
};

export default Contact;
