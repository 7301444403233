"use client";
import { useState } from "react";
import Image from "next/image";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function MakingChange() {
	const [expanded, setExpanded] = useState(false);

	const items = [
		{
			id: 0,
			text: "Converting Doctor's Handwritten Prescription into Text",
			description:
				"Doctors can now simply write prescriptions on paper, and our AI technology automatically reads and transcribes the information, and securely stores them in the patient's database, streamlining the process.",
			image: "/svg/chikitsa-change-1.svg",
		},
		{
			id: 1,
			text: "Executing Patient's",
			description:
				"Chikitsa ID, in collaboration with ABHA, offers one-time registration and subsequent appointments can be scheduled with a single QR scan.",
			image: "/svg/chikitsa-change-2.svg",
		},
		{
			id: 2,
			text: "Implementing Blockchain to store patients data securely",
			description:
				"In Chikitsa healthcare ecosystem, we leverage blockchain technology to ensure the secure storage of patient data and medical records. When a patient registers on Chikitsa platform and schedules an appointment with a doctor, this transaction is seamlessly recorded on our private enterprise blockchain network, built atop the robust Hyperledger Fabric framework.",
			image: "/svg/chikitsa-change-3.svg",
		},
	];

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<div className="flex justify-center items-center pt-4 sm:pt-20">
			<section
				id="making-change"
				className=" w-[100%] flex flex-col lg:flex-row items-center justify-evenly bg-[#F9FCFB] my-10 md:my-0 lg:pt-16"
			>
				<div className="w-full lg:w-[40%] px-4 sm:px-10  mt-12 lg:mt-0 lg:px-0 mb-10 lg:mb-0">
					<h3 className="heading uppercase font-[600] leading-[28px] sm:leading-[35px] text-[20px] sm:text-[30px]">
						We're making a <span className="text-[#1FBBE2]">change by</span>
					</h3>
					<div className="space-y-5">
						{items?.map((item, index) => (
							<Accordion
								key={item?.id}
								expanded={expanded === index}
								onChange={handleChange(index)}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}a-content`}
									id={`panel${index}a-header`}
									className={`text-[16px]  font-[500] leading-[24px] cursor-pointer pl-2.5 gap-8 rounded-sm transition-all duration-300 ease-in-out border-l-4 ${
										expanded === index
											? "border-[#053C61] text-[#053C61] font-[800]"
											: "border-[#A3A3A3] text-black"
									}`}
								>
									{item.text}
								</AccordionSummary>
								<AccordionDetails className="pl-2.5 border-l-4 border-[#053C61] transition-all duration-300 ease-in-out animate-fadeIn">
									<Typography className="paragraph_service text-[#BEBEBE] mt-0 text-justify">
										{item.description}
									</Typography>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</div>
				<div className="w-full lg:w-1/3 px-6 md:px-0 flex justify-center">
					{items?.map((item, index) => (
						<div
							key={item.id}
							className={`transition duration-300 ease-in-out ${
								expanded === index ? "block" : "hidden"
							}`}
						>
							<Image
								src={item.image}
								alt={`Chikitsa Change step ${index + 1}`}
								width={index === 0 ? 450 : 500}
								height={500}
								className="rounded-md"
							/>
						</div>
					))}
					{expanded === false && (
						<Image
							src="/svg/chikitsa-change-1.svg"
							alt="chikitsa.io"
							width={450}
							height={500}
							className="rounded-md"
						/>
					)}
				</div>
			</section>
		</div>
	);
}

export default MakingChange;
