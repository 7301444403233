"use client";
import Image from "next/image";
import Head from "next/head";
import { useRouter } from "next/navigation";
import AOS from "../Sections/AOS.jsx";

function Services() {
	const router = useRouter();
	const handleClick = (item) => {
		router.push(`/services/${item}`);
	};
	return (
		<div id="services" className="flex justify-center items-center">
			<Head>
				<title>Our Services - chikitsa.io</title>
				<meta
					name="description"
					content="Discover the comprehensive services offered by Chikitsa.io including Clinic Management, Pharmacy Management, Lab Management, Hospital Management, and Claim/TPA Management."
				/>
				<meta
					name="keywords"
					content="Clinic Management, Pharmacy Management, Lab Management, Hospital Management, Claim Management, TPA Management, healthcare software"
				/>
				<link rel="canonical" href="https://www.chikitsa.io/#services" />
			</Head>

			<section className="w-[100%] pt-14 flex flex-col justify-center items-center">
				<div className="flex flex-col justify-center items-center w-[80%]">
					<h2 className="heading text-[14px] sm:text-[24px]">
						Our <span className="text-[#1FBBE2]">Services</span>
					</h2>
					{/* Divider with dots */}
					<AOS />
				</div>

				{/* Clinic Management section */}
				<div className="container mx-auto mt-6 flex flex-col lg:flex-row justify-center items-center gap-10">
					<div className="w-auto lg:w-[550px] lg:h-[340px]">
						{/* Image for Clinic Management */}
						<Image
							src="/svg/service_1.svg"
							width={550}
							height={380}
							// layout="responsive"
							alt="Clinic Management Service - chikitsa.io"
						/>
					</div>
					<div className="w-full lg:w-[515px] lg:h-[300px] border-2 border-[#1FBBE2] rounded-[20px] p-4 sm:p-8 bg-white shadow-md relative">
						{/* Clinic Management details */}
						<h3 className="heading_service  text-[17px] sm:text-[28px] leading-[30px] sm:leading-[56px] mb-2">
							Clinic Management
						</h3>
						<p className="paragraph_service">
							Welcome to the future of healthcare administration with Chikitsa's
							Clinic Management Software.
						</p>
						<ul className="paragraph_service list-inside mt-4">
							<li className="break-words flex-shrink-0 relative pl-6">
								<span className="absolute left-1 top-[4px] mt-1 w-1 h-1 bg-[#7e7e7e] rounded-full"></span>
								Effortless Operations: Appointment Scheduling, Records,
								<br />
								<span>Billing, Secure Cloud</span>
							</li>
							<li className="break-words text-justify flex-shrink-0 relative pl-6">
								<span className="absolute left-1 top-[4px] mt-1 w-1 h-1 bg-[#7e7e7e] rounded-full"></span>
								User-friendly design
							</li>
							<li className="break-words text-justify flex-shrink-0 relative pl-6">
								<span className="absolute left-1 top-[4px] mt-1 w-1 h-1 bg-[#7e7e7e] rounded-full"></span>
								Exceptional Patient Care
							</li>
						</ul>
						{/* Play button */}
						<div
							className="absolute bottom-[-25px] right-[20px] z-10 hover:cursor-pointer"
							onClick={() => handleClick("clinic-management")}
						>
							<Image
								src="/svg/play_button.svg"
								width={55}
								height={55}
								style={{ width: "55px", height: "55px" }}
								// layout="fixed"
								alt="Play Video - chikitsa.io"
							/>
						</div>
					</div>
				</div>

				{/* Pharmacy Management section */}
				<div className="container mx-auto mt-16 flex flex-col lg:flex-row justify-center items-center gap-10">
					<div className="block sm:flex justify-center items-center lg:hidden w-full lg:w-[550px] lg:h-[340px]">
						{/* Image for Pharmacy Management */}
						<Image
							src="/svg/service_2.svg"
							width={600}
							height={400}
							// layout="responsive"
							alt="Pharmacy Management Service - chikitsa.io"
						/>
					</div>
					<div className="w-full lg:w-[515px] lg:h-[300px] border-2 border-[#1FBBE2] rounded-[20px] p-4 sm:p-8 bg-white shadow-md relative">
						{/* Pharmacy Management details */}
						<h3 className="heading_service text-[17px] sm:text-[28px] leading-[30px] sm:leading-[56px] mb-2">
							Pharmacy Management
						</h3>
						<p className="paragraph_service">
							Operational excellence with end-to-end procurement management
						</p>
						<ul className="paragraph_service list-disc list-inside mt-4">
							<li>Reduce Paperwork</li>
							<li>Optimized Inventory</li>
							<li>Faster Billing</li>
							<li>Integrated Accounting</li>
							<li>Robust Security</li>
						</ul>
						{/* Play button */}
						<div
							className="absolute bottom-[-25px] right-[18px] z-10 hover:cursor-pointer"
							onClick={() => handleClick("pharmacy-management")}
						>
							<Image
								src="/svg/play_button.svg"
								width={55}
								height={55}
								style={{ width: "55px", height: "55px" }}
								// layout="fixed"
								alt="Play Video - chikitsa.io"
							/>
						</div>
					</div>
					<div className="hidden lg:block w-full lg:w-[550px] lg:h-[340px]">
						{/* Image for Pharmacy Management */}
						<Image
							src="/svg/service_2.svg"
							width={600}
							height={400}
							// layout="responsive"
							alt="Pharmacy Management Service - chikitsa.io"
						/>
					</div>
				</div>

				{/* Lab Management section */}
				<div className="container mx-auto mt-16 flex flex-col lg:flex-row justify-center items-center gap-10">
					<div className="w-auto lg:w-[550px] lg:h-[340px]">
						{/* Image for Lab Management */}
						<Image
							src="/svg/service_3.svg"
							width={600}
							height={400}
							// // layout="responsive"
							alt="Lab Management Service - chikitsa.io"
						/>
					</div>
					<div className="w-full lg:w-[515px]  lg:h-[320px] border-2 border-[#1FBBE2] rounded-[20px] p-4 sm:p-8 bg-white shadow-md relative">
						{/* Lab Management details */}
						<h3 className="heading_service text-[17px] sm:text-[28px] leading-[30px] sm:leading-[56px] mb-2">
							Lab Management
						</h3>
						<p className="paragraph_service mb-4">
							Say goodbye to lab chaos, Speed up your lab's turnaround time
						</p>
						<ul className="paragraph_service list-disc list-inside">
							<li>Automated Sample Tracking</li>
							<li>Integration with Testing Equipment</li>
							<li>Live Analytics at Your Fingertips</li>
							<li>Patient List: See it All</li>
							<li>Track Progress Seamlessly</li>
							<li>Know Your Status: Test Collected, Done</li>
						</ul>
						{/* Play button */}
						<div
							className="absolute bottom-[-25px] right-[20px] z-10 hover:cursor-pointer"
							onClick={() => handleClick("lab-management")}
						>
							<Image
								src="/svg/play_button.svg"
								width={55}
								height={55}
								style={{ width: "55px", height: "55px" }}
								alt="Play Video - chikitsa.io"
							/>
						</div>
					</div>
				</div>

				{/* Hospital Management section */}
				<div className="container mx-auto mt-16 flex flex-col lg:flex-row justify-center items-center gap-10">
					<div className="block lg:hidden w-auto lg:w-[550px] lg:h-[340px]">
						{/* Image for Claim/TPA Management */}
						<Image
							src="/svg/service_4.svg"
							width={600}
							height={400}
							// layout="responsive"
							alt="Hospital Management Service - chikitsa.io"
						/>
					</div>
					<div className="w-full lg:w-[515px] lg:h-[280px] border-2 border-[#1FBBE2] rounded-[20px] p-4 sm:p-8 bg-white shadow-md relative">
						{/* Hospital Management details */}
						<h3 className="heading_service text-[17px] sm:text-[28px] leading-[30px] sm:leading-[56px] mb-2">
							Hospital Management
						</h3>
						<p className="paragraph_service">
							Unleash the Power of Smart Hospitals with Chikitsa's HMIS
							comprehensive healthcare management
						</p>
						<ul className="paragraph_service list-disc list-inside mt-4">
							<li>From Inpatient Care to Outpatient Services</li>
							<li>ABDM-compliant Solution</li>
							<li>Enhanced Patient Satisfaction</li>
							<li>Blockchain-secured data meets highest standards.</li>
						</ul>
						{/* Play button */}
						<div
							className="absolute bottom-[-25px] right-[18px] z-10 hover:cursor-pointer"
							onClick={() => handleClick("hospital-management")}
						>
							<Image
								src="/svg/play_button.svg"
								width={55}
								height={55}
								// layout="fixed"
								style={{ width: "55px", height: "55px" }}
								alt="Play Video - chikitsa.io"
							/>
						</div>
					</div>
					<div className="hidden lg:block w-full lg:w-[550px] lg:h-[340px]">
						{/* Image for Hospital Management */}
						<Image
							src="/svg/service_4.svg"
							width={600}
							height={400}
							// layout="responsive"
							alt="Hospital Management Service - chikitsa.io"
						/>
					</div>
				</div>

				{/* Claim/TPA Management section */}
				<div className="container mx-auto mt-16 flex flex-col lg:flex-row justify-center items-center gap-10">
					<div className="w-auto lg:w-[450px] lg:h-[340px]">
						{/* Image for Claim/TPA Management */}
						<Image
							src="/svg/service_5.svg"
							width={600}
							height={400}
							// layout="responsive"
							alt="Claim/TPA Management Service - chikitsa.io"
						/>
					</div>
					<div className="w-full lg:w-[615px] lg:h-[300px] border-2 border-[#1FBBE2] rounded-[20px] p-4 sm:p-8 bg-white shadow-md relative">
						{/* Claim/TPA Management details */}
						<h3 className="heading_service text-[17px] sm:text-[28px] leading-[30px] sm:leading-[56px] mb-2">
							Claim/TPA Management
						</h3>
						<ul className="paragraph_service list-disc list-inside">
							<li>Faster Settlements</li>
							<li>Reduce Errors, Maximize Revenue</li>
							<li>Actionable Insights</li>
							<li>Data-Driven Decisions at Your Fingertips</li>
							<li>Seamless TPA Integration</li>
							<li>Simplified Billing for All</li>
							<li>Easy Healthcare Transactions</li>
						</ul>
						{/* Play button */}
						<div
							className="absolute bottom-[-25px] right-[20px] z-10 hover:cursor-pointer"
							onClick={() => handleClick("claim-management")}
						>
							<Image
								src="/svg/play_button.svg"
								width={55}
								height={55}
								// layout="fixed"
								style={{ width: "55px", height: "55px" }}
								alt="Play Video - chikitsa.io"
							/>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Services;
